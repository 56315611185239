import Content from "../components/Content";
const NotFound = () => {
  return (
    <Content>
      <h1 className="error">Nie znaleziono strony</h1>
    </Content>
  );
};

export default NotFound;
